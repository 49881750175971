import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import Loader from "../components/Loader"
import { getCourses } from "../redux/slices/course"
import styles from "./CoursesPage.module.css"

const CoursesPage = () => {
  const dispatch = useDispatch();
  const { courses, isLoading } = useSelector((state) => state.course);

  useEffect(() => {
    dispatch(getCourses());
  }, [dispatch]);

  return isLoading ? (
    <Loader></Loader>
  ) : (
    <div className={styles.container}>
      <h2 className={styles.title}>Список доступных курсов</h2>
      <div className={styles.courses}>
        {courses?.map((course, i) => {
          return (
            <div className={styles.course} key={i}>
              <div className={styles.course__header}>
                <h3 className={styles.course__name}>
                  <span className={styles.course__title}>{course.title}</span>
                  {course.type && (
                    <span className={styles.course__type}>
                      {" "}
                      ({course.type})
                    </span>
                  )}
                </h3>
                {/* <div className={styles.course__price}>
                  {course.price} рублей
                </div> */}
              </div>
              <div className={styles.course__body}>
                <h4 className={styles.course__subtitle}>{course.subtitle}</h4>
                <div className={styles.course__info}>
                  <p className={styles.course__description}>
                    {course.description}
                  </p>
                  <p className={styles.course__description}>
                    {course.availability}
                  </p>
                </div>
              </div>
              <div className={styles.course__footer}>
                <div className={styles.course__users}>
                  <span className={styles.course__participate}>
                    Участвует сотрудников:{" "}
                  </span>
                  <span className={styles.course__members}>
                    {course.members}
                  </span>
                </div>
                <div className={styles.course__buttons}>
                  {course.id !== 1 && course.id !== 9 && course.id !== 11 && (
                    <Link
                      to={`/courses/${course.demoId}`}
                      className={[styles.button, styles.button_secondary].join(
                        " "
                      )}
                    >
                      Попробовать Демо
                    </Link>
                  )}
                  <Link
                    to={`/courses/${course.id}`}
                    className={[styles.button, styles.button_primary].join(" ")}
                  >
                    Выбрать курс
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CoursesPage;
